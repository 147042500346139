import React from 'react';
import {Section, SectionActions, SectionHeader} from '../Section';
import {Trans} from 'gatsby-plugin-react-i18next';
import {SignUpButton} from '../SignUpButton';
import {Partners} from '../Partners';
import {Content} from '../Content';
import {containerSize} from '../../../types/responsive';

export const IntegrationGuide: React.FC<{
  title: string | JSX.Element;
  align?: 'center' | 'left' | 'right';
}> = ({title, align = 'center', children}) => {
  return (
    <Content fullWidth>
      <Section textAlign="center" sx={{maxWidth: containerSize, margin: 'auto'}}>
        <div>
          <SectionHeader underline tagName="h3">
            {title}
          </SectionHeader>
          <p style={{maxWidth: 940, margin: 'auto', textAlign: align}}>{children}</p>
          <SectionActions>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
          <Partners />
        </div>
      </Section>
    </Content>
  );
};
