import styled from 'styled-components';
import React from 'react';
import {ScreenSizes} from '../../../types/responsive';
import {StyledImg} from '../StyledSx';

export const MainTitleImg = styled(StyledImg)`
  position: absolute;
  left: 52%;
  top: 40px;
  width: 600px;
  z-index: 10;
  filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.2));

  @media (max-width: ${ScreenSizes.md}) {
    display: none;
  }
`;
