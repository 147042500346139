// i18next-extract-mark-ns-start cofidis

//Landing redirected to /payment-methods to hide from BdE license - Discussed with David and Alex on 2024-08-08

import {AnchorLink} from 'components/AnchorLink';
//Payment methods
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {List} from 'components/List';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {links} from 'global.config';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import cofidis_monei from 'images/cofidis_monei.svg';
import cofidis4x from 'images/4xcard_cofidis_logo.svg';
import cofidis6x12x24x from 'images/cofidis_payline_6x12x24x.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {graphql} from 'gatsby';
import {IndexBackground} from 'components/landings/IndexBackground';
import {MainTitleContent} from 'components/payment-methods/MainTitleContent';
import {MainTitleImg} from 'components/payment-methods/MainTitleImg';
import {BlogLink} from 'components/links/Blog';
import {IntegrationGuide} from 'components/payment-methods/IntegrationGuide';
import {InternalPageLink} from 'components/links/Pages';

const LogoImg = styled.img`
  opacity: 0.7;
  width: auto;
`;

const Cofidis: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        title={t('Installment payments for e-commerce via Cofidis')}
        description={t(
          'Add Cofidis as an instant credit payment method to your online store in a quick and simple way thanks to MONEI, and start increasing your sales.'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent sx={{maxWidth: {all: '550px', md: '100%'}}}>
              <SectionHeader underline tagName="h1">
                <Trans>Installment payments for e-commerce with Cofidis</Trans>
              </SectionHeader>
              <Trans parent="p">
                Use the MONEI payment gateway to integrate Cofidis into your online store and offer
                installment payments to your customers at the time of purchase. Sell more with
                financing solutions, increase your conversion and raise the average ticket of your
                business.
              </Trans>
              <Trans parent="p">
                With Cofidis Pay you can offer your customers a new flexible payment solution for
                their online purchases, easy, fast and secure.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg
              src={cofidis_monei}
              alt="MONEI payment gateway"
              title="MONEI payment gateway"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Let shoppers pay in 4 installments</Trans>
              </SectionHeader>
              <Trans parent="p">
                MONEI’s payment gateway solution lets you accept{' '}
                <InternalPageLink slug="payment-methods">many payment methods</InternalPageLink> in
                a single platform. We adapt to your customers' needs with the help of Cofidis,
                Spain’s trusted instant credit company.
              </Trans>
              <InternalPageLink slug="cofidis">
                <LogoImg src={cofidis4x} alt="Cofidis 4xCard" height={60} />
              </InternalPageLink>
              <List>
                <Trans parent="li">
                  Pay in 4 installments over the course of 90 days with a credit or debit card
                </Trans>
                <Trans parent="li">From 75 € to 1000 €</Trans>
                <Trans parent="li">You only need your ID card and bank card</Trans>
                <Trans parent="li">Instant answer when the purchase is being processed</Trans>
                <Trans parent="li">Settlement in 24 hours</Trans>
              </List>
              <Trans parent="p">
                <InternalPageLink slug="cofidis-pay">
                  <LogoImg src={cofidis6x12x24x} alt="6x12x24x Cofidis Pay" height={60} />
                </InternalPageLink>
              </Trans>
              <List>
                <Trans parent="li">
                  From 75 € to 3000 € in up to 50 installments with direct debit.
                </Trans>
                <Trans parent="li">The customer only needs their ID and a bank statement</Trans>
                <Trans parent="li">
                  Instant answer up to 1.000 €, 48h max for purchases up to 3.000 €
                </Trans>
                <Trans parent="li">Settlement in 48 hours</Trans>
              </List>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Boost customer engagement and increase sales</Trans>
              </SectionHeader>
              <Trans parent="p">
                In 2020, the use of installment payment options{' '}
                <BlogLink slug="buy-now-pay-later">grew by 55% in Spain</BlogLink>. Cofidis is known
                and trusted by many. Offer Cofidis payment methods to improve your customers
                satifaction, keep them coming back and trust your online store. According to a
                Cofidis survey in 2021:
              </Trans>
              <List>
                <Trans parent="li">98% of customers are satisfied with the 4xCard product</Trans>
                <Trans parent="li">
                  98% would use 6x12x24x Cofidis Pay again for future purchases
                </Trans>
                <Trans parent="li">
                  44% would have postponed their purchase if the 4xCard option was not available.
                </Trans>
              </List>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>How Cofidis installment payments work</Trans>
              </SectionHeader>
              <Trans parent="p">
                After you configure the{' '}
                <AnchorLink href="https://support.monei.com/hc/articles/4402319128465-How-do-I-activate-Cofidis-installment-payments-">
                  Cofidis payment method
                </AnchorLink>{' '}
                for your e-commerce store, customers can select 4xCard or 6x12x24x at checkout.
              </Trans>
              <Trans parent="p">
                For shoppers,{' '}
                <AnchorLink href="https://monei.com/cofidis/">
                  the checkout process is simple
                </AnchorLink>
                : they only have to provide their personal ID and it takes less than 3 minutes to
                complete the online application.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <IntegrationGuide title={<Trans>Quick and easy e-commerce integration</Trans>}>
        <Trans>
          Use MONEI to add Cofidis installment payments to major e-commerce platforms like{' '}
          <InternalPageLink slug="installment-payments-for-shopify">Shopify</InternalPageLink>,{' '}
          <InternalPageLink slug="installment-payments-for-woocommerce">
            WooCommerce
          </InternalPageLink>
          , <InternalPageLink slug="buy-now-pay-later-for-prestashop">PrestaShop</InternalPageLink>,
          and <InternalPageLink slug="installment-payments-for-wix">Wix</InternalPageLink> or
          integrate with your custom-built website using{' '}
          <a href={links.api} target="_blank" rel="noopener noreferrer">
            MONEI’s API
          </a>
          .
        </Trans>
      </IntegrationGuide>
    </>
  );
};

export default Cofidis;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "cofidis"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
